<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#005f32"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#005f32" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #ffffff">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #ffffff">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center>
      <v-flex xs12>
        <v-img
          src="../assets/Images/greenBG.jpg"
          class="align-center white--text"
        >
          <v-layout wrap>
            <v-flex xs12 pt-lg-16>
              <span class="oswaldbold xlarge" style="font-size: 60px">{{
                $route.query.DonationName
              }}</span>
            </v-flex>
          </v-layout>
        </v-img>
      </v-flex>
      <v-flex xs12 sm10 xl9 py-12>
        <span class="medium popregular">
          <!-- The second wave of COVID-19 in India has affected many families
          including those of frontline forest staff and wildlife
          conservationists as they continued to work in the field day in and out
          to protect wildlife. Your donation to the Conservation Heroes Covid
          Casualty Fund will help recognize their life’s contribution to nature
          conservation while providing financial support to their families when
          they need it the most. -->
        </span>
      </v-flex>
      <v-flex xs12 sm10 pa-2>
        <v-layout wrap>
          <v-flex xs12 md6 px-sm-4>
            <v-layout wrap>
              <v-flex xs12>
                <!-- <v-card elevation="3" v-if="firstCard">
                  <v-layout wrap justify-center pa-3 pa-sm-0>
                    <v-flex
                      xs12
                      py-3
                      style="
                        background-color: #f2f2f2;
                        margin-bottom: 1px solid grey;
                      "
                    >
                      <span class="popregular ml">Donate Now</span>
                    </v-flex>

                    <v-flex xs12 pt-10>
                      <span
                        style="
                          color: #555;
                          font-family: montserratbold;
                          font-size: 20px;
                        "
                        >Select an Amount</span
                      >
                    </v-flex>
                    <v-flex xs12>
                      <v-layout wrap pt-8 pa-8 class="popregular">
                        <v-flex xs12 sm6 md12 lg6 pa-2>
                          <v-btn
                            @click="donation = 5000"
                            outlined
                            block
                            height="80px"
                            color="#e27725"
                            style="font-size: 20px"
                            dark
                            >₹5,000</v-btn
                          >
                        </v-flex>
                        <v-flex xs12 sm6 md12 lg6 pa-2>
                          <v-btn
                            height="80px"
                            color="#e27725"
                            dark
                            style="font-size: 20px"
                            block
                            >₹10,000</v-btn
                          >
                        </v-flex>
                        <v-flex xs12 sm6 md12 lg6 pa-2>
                          <v-btn
                            height="80px"
                            color="#e27725"
                            dark
                            style="font-size: 20px"
                            block
                            >₹20,000</v-btn
                          >
                        </v-flex>
                        <v-flex xs12 sm6 md12 lg6 pa-2>
                          <v-btn
                            id="my_button"
                            @click="toTextField()"
                            height="80px"
                            color="#e27725"
                            dark
                            style="font-size: 18px; text-transform: none"
                            block
                            >Any other amount</v-btn
                          >
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs12 px-10>
                      <v-text-field
                        id="my_textbox"
                        height="80px"
                        outlined
                        v-model="donation"
                        class="center"
                      >
                        <template v-slot:prepend-inner>
                          <v-layout justify-center pt-4>
                            <v-flex pt-0 class="align-self-center">
                              <span
                                style="
                                  font-family: poppinsregular;
                                  font-size: 18px;
                                  color: #000;
                                "
                                >INR ₹</span
                              >
                            </v-flex>
                            
                          </v-layout>
                        </template>
                      </v-text-field>
                    </v-flex>
                    <v-flex xs12 sm6 py-8>
                      <v-btn
                        @click="(secondCard = true), (firstCard = false)"
                        height="80px"
                        color="#e27725"
                        dark
                        style="font-size: 24px; text-transform: none"
                        block
                        >Continue <v-icon>mdi-chevron-right</v-icon></v-btn
                      >
                    </v-flex>
                    <v-flex
                      class="popregular"
                      xs12
                      py-5
                      text-center
                      style="background-color: #f2f2f2"
                    >
                      <span><v-icon small>mdi-lock</v-icon> </span>
                      <span>Secure Donation</span>
                    </v-flex>
                  </v-layout>
                </v-card>
                <v-card elevation="3" v-if="secondCard">
                  <v-layout wrap justify-center pa-3 class="animate" id="div1">
                    <v-flex xs12>
                      <v-layout wrap style="background-color: #f2f2f2">
                        <v-flex
                          xs1
                          align-self-center
                          style="background-color: #f2f2f2"
                        >
                          <v-icon>mdi-chevron-left</v-icon>
                        </v-flex>
                        <v-flex
                          xs10
                          py-3
                          style="
                            background-color: #f2f2f2;
                            margin-bottom: 1px solid grey;
                          "
                        >
                          <span class="popregular ml"
                            >Add Your Information</span
                          >
                        </v-flex>
                        <v-flex xs1 py-3>
                          <v-spacer></v-spacer>
                        </v-flex>
                      </v-layout>
                    </v-flex>

                    <v-flex xs12 py-6 text-center style="color: grey">
                      <span class="popregular medium">Who's giving today?</span>
                      <br />

                      <i>
                        <span class="popregular"
                          >We’ll never share this information with anyone</span
                        ></i
                      >
                    </v-flex>
                    <v-flex xs6 px-5>
                      <v-text-field
                        class="center2"
                        outlined
                        prepend-inner-icon="mdi-account"
                        label="Frist Name"
                      >
                      </v-text-field>
                    </v-flex>
                    <v-flex xs6 px-5>
                      <v-text-field
                        outlined
                        class="center2"
                        label="Last Name (*optional)"
                      >
                      </v-text-field>
                    </v-flex>
                    <v-flex xs12 px-5>
                      <v-text-field
                        outlined
                        prepend-inner-icon="mdi-pillar"
                        class="center2"
                        label="Company Name(*optional)"
                      >
                      </v-text-field>
                    </v-flex>
                    <v-flex xs12 px-5>
                      <v-text-field
                        outlined
                        prepend-inner-icon="mdi-email"
                        class="center2"
                        label="Email Address"
                      >
                      </v-text-field>
                    </v-flex>
                    <v-flex xs12 px-5>
                      <v-text-field outlined prepend-inner-icon="mdi-phone">
                      </v-text-field>
                    </v-flex>

                    <v-flex xs12 px-5>
                      <v-radio-group v-model="radio" column>
                        <v-layout wrap>
                          <v-flex xs12>
                            <v-card style="margin-bottom: 15px" color="#f2f2f2">
                              <v-layout wrap>
                                <v-flex xs11 py-3 pl-1>
                                  <v-radio
                                    class="boldR"
                                    color="#e27725"
                                    label="Donate with PayUmoney"
                                    value="radio-1"
                                  ></v-radio>
                                </v-flex>
                                <v-flex xs1 align-self-center>
                                  <v-icon>mdi-hand-heart</v-icon>
                                </v-flex>
                              </v-layout>
                            </v-card>
                          </v-flex>
                          <v-flex>
                            <v-card color="#f2f2f2">
                              <v-layout wrap>
                                <v-flex xs11 py-3 pl-1>
                                  <v-radio
                                    class="boldR"
                                    color="#e27725"
                                    label="Donate with CCAvenue"
                                    value="radio-2"
                                  ></v-radio>
                                </v-flex>
                                <v-flex xs1 align-self-center>
                                  <v-icon>mdi-hand-heart</v-icon>
                                </v-flex>
                              </v-layout>
                            </v-card>
                          </v-flex>
                        </v-layout>
                      </v-radio-group>
                    </v-flex>

                    <v-flex xs12 px-5 pt-2>
                      <v-select outlined> </v-select>
                    </v-flex>
                    <v-flex xs12 px-5>
                      <v-text-field
                        outlined
                        class="center2"
                        label="Address line 1"
                      >
                      </v-text-field>
                    </v-flex>
                    <v-flex xs12 px-5>
                      <v-text-field
                        outlined
                        class="center2"
                        label="Address line 2"
                      >
                      </v-text-field>
                    </v-flex>
                    <v-flex xs12 px-5>
                      <v-text-field outlined class="center2" label="City">
                      </v-text-field>
                    </v-flex>
                    <v-flex xs6 px-5>
                      <v-text-field outlined class="center2" label="State">
                      </v-text-field>
                    </v-flex>
                    <v-flex xs6 px-5>
                      <v-text-field
                        outlined
                        class="center2"
                        label="Zip/Postal Code"
                      >
                      </v-text-field>
                    </v-flex>

                    <v-flex xs12 sm6 py-8>
                      <v-btn
                        height="80px"
                        color="#e27725"
                        dark
                        style="
                          font-size: 22px;
                          text-transform: none;
                          font-family: poppinsregular;
                        "
                        block
                        >Donate Now
                      </v-btn>
                    </v-flex>

                    <v-flex
                      class="popregular"
                      xs12
                      py-5
                      text-center
                      style="background-color: #f2f2f2"
                    >
                      <span><v-icon small>mdi-lock</v-icon> </span>
                      <span>Secure Donation</span>
                    </v-flex>
                  </v-layout>
                </v-card> -->

                <Donationcard />
              </v-flex>
              <v-flex py-4 py-lg-16>
                <span class="popregular medium">
                  All the information shared during the donation process will be
                  kept as confidential information and we don’t share any data
                  outside WTI for any purposes.
                </span>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12 md6 px-2 px-lg-7 pt-sm-6 pt-md-0>
            <div>
              <!-- 
               :height="
                  $vuetify.breakpoint.name == 'xs'
                    ? '300px'
                    : '500px' && $vuetify.breakpoint.name == 'md'
                    ? '400px'
                    : '500px' && $vuetify.breakpoint.name == 'lg'
                    ? '450px'
                    : '500px' && $vuetify.breakpoint.name == 'xl'
                    ? '700px'
                    : '500px'
                "
                -->
              <v-carousel
                interval="3000"
                cycle
                hide-delimiters
                :height="
                  $vuetify.breakpoint.name == 'xs'
                    ? '300px'
                    : '500px' && $vuetify.breakpoint.name == 'md'
                    ? '300px'
                    : '500px' && $vuetify.breakpoint.name == 'lg'
                    ? '400px'
                    : '500px' && $vuetify.breakpoint.name == 'xl'
                    ? '700px'
                    : '500px'
                "
               
              >
                <v-carousel-item  height="100%" v-for="(item, i) in images" :key="i">
                  <v-sheet height="100%">
                  <v-img contain :src="mediaURL + item"> </v-img>
                  </v-sheet>
                </v-carousel-item>
              </v-carousel>
            </div>
            <div class="medium popregular py-6">
              <span>Email all your donation queries to us on</span> <br />
              <a href="mailto:donations@wti.org.in" style="color: green"
                >donations@wti.org.in</a
              >
              <!-- <span>donations@wti.org.in</span> -->
            </div>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
import Donationcard from "./donationCard";
export default {
  data() {
    return {
      donation: 5000,
      firstCard: true,
      secondCard: false,
      radio: null,
      images: [],
      donationData: [],
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      msg: null,
      timeout: 3000,
    };
  },
  components: {
    Donationcard,
  },
  mounted() {
    this.getList();
  },

  methods: {
    toTextField() {
      this.donation = null;
      document.getElementById("my_button").onclick = function () {
        document.getElementById("my_textbox").focus();
      };
    },

    getList() {
      this.appLoading = true;
      axios({
        method: "get",
        url: "/donationtype/getbyname",
        params: {
          name: this.$route.query.DonationName,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.donationData = response.data.data;
            this.images = response.data.data.images;
          } else {
            this.showSnackBar = true;
            this.msg = response.data.msg;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err());
          this.ServerError = true;
        });
    },
  },
};
</script>
<style  scoped>
.v-text-field--outlined >>> fieldset {
  border-color: grey;
  border-radius: 5px;
}
.v-radio >>> label {
  font-size: 20px;
  font-family: poppinsregular;
  color: black;
}
.center >>> input {
  text-align: center;
  font-family: poppinsregular;
  font-size: 32px;
}
.center2 >>> label {
  text-align: center;
  font-family: poppinsregular;
  font-size: 20px;
}
.boldR >>> label {
  font-family: poppinsbold;
}
.animate {
  position: relative;
  animation: mymove 0.25s;
}
@keyframes mymove {
  from {
    left: -500px;
  }
  to {
    left: 0px;
  }
}
#div1 {
  animation-timing-function: linear;
}

.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear; /* Safari */
  animation: spin 2s linear;
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>