<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#005f32"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#005f32" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #ffffff">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #ffffff">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
      <v-flex xs12>
        <v-card elevation="3" v-if="firstCard">
          <v-layout wrap justify-center pa-3 pa-sm-0>
            <v-flex
              xs12
              py-3
              style="background-color: #f2f2f2; margin-bottom: 1px solid grey"
            >
              <span class="popregular ml">Donate Now  </span>
            </v-flex>
            <v-flex xs12 py-4
              ><span
                style="
                  color: #555;
                  font-family: montserratbold;
                  font-size: 20px;
                "
                >Select Country
              </span></v-flex
            >
            <v-flex xs12 px-3 px-md-12>
              <v-select
                background-color="#e27725"
                solo
                ref="country"
                dark
                label="National Of"
                placeholder="Country"
                class="center4"
                :items="regions"
                :rules="[rules.required]"
                v-model="country"
                item-text="name"
                item-value="name"
                item-color="#FF1313"
              >
              </v-select>
            </v-flex>

            <v-flex xs12 pt-2>
              <span
                style="
                  color: #555;
                  font-family: montserratbold;
                  font-size: 20px;
                "
                >Select an Amount</span
              >
            </v-flex>
            <v-flex xs12>
              <v-layout wrap pt-8 pa-8 class="popregular">
                <v-flex xs12 sm6 md12 lg6 pa-2>
                  <v-btn
                    @click="assign(BTN1)"
                    :outlined="outline1 ? true : false"
                    block
                    height="60px"
                    color="#e27725"
                    style="font-size: 20px"
                    dark
                    >{{ sign }}{{ BTN1 }}</v-btn
                  >
                </v-flex>
                <v-flex xs12 sm6 md12 lg6 pa-2>
                  <v-btn
                    :outlined="outline2 ? true : false"
                    @click="assign(BTN2)"
                    height="60px"
                    color="#e27725"
                    dark
                    style="font-size: 20px"
                    block
                    >{{ sign }}{{ BTN2 }}</v-btn
                  >
                </v-flex>
                <v-flex xs12 sm6 md12 lg6 pa-2>
                  <v-btn
                    :outlined="outline3 ? true : false"
                    @click="assign(BTN3)"
                    height="60px"
                    color="#e27725"
                    dark
                    style="font-size: 20px"
                    block
                    >{{ sign }}{{ BTN3 }}</v-btn
                  >
                </v-flex>
                <v-flex xs12 sm6 md12 lg6 pa-2>
                  <v-btn
                    :outlined="outline4 ? true : false"
                    id="my_button"
                    @click="toTextField()"
                    height="60px"
                    color="#e27725"
                    dark
                    style="font-size: 16px; text-transform: none"
                    block
                    >Any other amount</v-btn
                  >
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 px-10>
              <v-text-field
                type="number"
                id="my_textbox"
                height="70px"
                outlined
                v-model="donation"
                class="center"
              >
                <template v-slot:prepend-inner>
                  <v-layout justify-center pt-4>
                    <v-flex
                      pt-0
                      class="align-self-center"
                      style="border-right: 1px dotted grey"
                    >
                      <span
                        style="
                          padding-right: 3px;
                          font-family: poppinsregular;
                          font-size: 12px;
                          color: #000;
                        "
                        >{{ Cname + sign }}</span
                      >
                    </v-flex>
                  </v-layout>
                </template>
              </v-text-field>
            </v-flex>
            <v-flex xs12 sm6 py-8>
              <v-btn
                @click="(secondCard = true), (firstCard = false)"
                height="60px"
                color="#e27725"
                dark
                style="font-size: 24px; text-transform: none"
                block
                >Continue <v-icon>mdi-chevron-right</v-icon></v-btn
              >
            </v-flex>
            <v-flex
              class="popregular"
              xs12
              py-5
              text-center
              style="background-color: #f2f2f2"
            >
              <span><v-icon small>mdi-lock</v-icon> </span>
              <span>Secure Donation</span>
            </v-flex>
          </v-layout>
        </v-card>
        <!-- <v-card elevation="3" v-if="secondCard">
                  <v-layout wrap justify-center pa-3 class="animate" id="div1">
                    <v-flex xs12>
                      <v-layout wrap style="background-color: #f2f2f2">
                        <v-flex
                          xs1
                          align-self-center
                          style="background-color: #f2f2f2"
                        >
                          <v-icon>mdi-chevron-left</v-icon>
                        </v-flex>
                        <v-flex
                          xs10
                          py-3
                          style="
                            background-color: #f2f2f2;
                            margin-bottom: 1px solid grey;
                          "
                        >
                          <span class="popregular ml"
                            >Add Your Information</span
                          >
                        </v-flex>
                        <v-flex xs1 py-3>
                          <v-spacer></v-spacer>
                        </v-flex>
                      </v-layout>
                    </v-flex>

                    <v-flex xs12 py-6 text-center style="color: grey">
                      <span class="popregular medium">Who's giving today?</span>
                      <br />

                      <i>
                        <span class="popregular"
                          >We’ll never share this information with anyone</span
                        ></i
                      >
                    </v-flex>
                    <v-flex xs6 px-5>
                      <v-text-field
                        class="center2"
                        outlined
                        prepend-inner-icon="mdi-account"
                        label="Frist Name"
                      >
                      </v-text-field>
                    </v-flex>
                    <v-flex xs6 px-5>
                      <v-text-field
                        outlined
                        class="center2"
                        label="Last Name (*optional)"
                      >
                      </v-text-field>
                    </v-flex>
                    <v-flex xs12 px-5>
                      <v-text-field
                        outlined
                        prepend-inner-icon="mdi-pillar"
                        class="center2"
                        label="Company Name(*optional)"
                      >
                      </v-text-field>
                    </v-flex>
                    <v-flex xs12 px-5>
                      <v-text-field
                        outlined
                        prepend-inner-icon="mdi-email"
                        class="center2"
                        label="Email Address"
                      >
                      </v-text-field>
                    </v-flex>
                    <v-flex xs12 px-5>
                      <v-text-field outlined prepend-inner-icon="mdi-phone">
                      </v-text-field>
                    </v-flex>

                    <v-flex xs12 px-5>
                      <v-radio-group v-model="radio" column>
                        <v-layout wrap>
                          <v-flex xs12>
                            <v-card style="margin-bottom: 15px" color="#f2f2f2">
                              <v-layout wrap>
                                <v-flex xs11 py-3 pl-1>
                                  <v-radio
                                    class="boldR"
                                    color="#e27725"
                                    label="Donate with PayUmoney"
                                    value="radio-1"
                                  ></v-radio>
                                </v-flex>
                                <v-flex xs1 align-self-center>
                                  <v-icon>mdi-hand-heart</v-icon>
                                </v-flex>
                              </v-layout>
                            </v-card>
                          </v-flex>
                          <v-flex>
                            <v-card color="#f2f2f2">
                              <v-layout wrap>
                                <v-flex xs11 py-3 pl-1>
                                  <v-radio
                                    class="boldR"
                                    color="#e27725"
                                    label="Donate with CCAvenue"
                                    value="radio-2"
                                  ></v-radio>
                                </v-flex>
                                <v-flex xs1 align-self-center>
                                  <v-icon>mdi-hand-heart</v-icon>
                                </v-flex>
                              </v-layout>
                            </v-card>
                          </v-flex>
                        </v-layout>
                      </v-radio-group>
                    </v-flex>

                    <v-flex xs12 px-5 pt-2>
                      <v-select outlined> </v-select>
                    </v-flex>
                    <v-flex xs12 px-5>
                      <v-text-field
                        outlined
                        class="center2"
                        label="Address line 1"
                      >
                      </v-text-field>
                    </v-flex>
                    <v-flex xs12 px-5>
                      <v-text-field
                        outlined
                        class="center2"
                        label="Address line 2"
                      >
                      </v-text-field>
                    </v-flex>
                    <v-flex xs12 px-5>
                      <v-text-field outlined class="center2" label="City">
                      </v-text-field>
                    </v-flex>
                    <v-flex xs6 px-5>
                      <v-text-field outlined class="center2" label="State">
                      </v-text-field>
                    </v-flex>
                    <v-flex xs6 px-5>
                      <v-text-field
                        outlined
                        class="center2"
                        label="Zip/Postal Code"
                      >
                      </v-text-field>
                    </v-flex>

                    <v-flex xs12 sm6 py-8>
                      <v-btn
                        height="80px"
                        color="#e27725"
                        dark
                        style="
                          font-size: 22px;
                          text-transform: none;
                          font-family: poppinsregular;
                        "
                        block
                        >Donate Now
                      </v-btn>
                    </v-flex>

                    <v-flex
                      class="popregular"
                      xs12
                      py-5
                      text-center
                      style="background-color: #f2f2f2"
                    >
                      <span><v-icon small>mdi-lock</v-icon> </span>
                      <span>Secure Donation</span>
                    </v-flex>
                  </v-layout>
                </v-card> -->
        <v-card elevation="3" v-if="secondCard">
          <v-form ref="file">
            <v-layout wrap justify-center pa-3 class="animate" id="div1">
              <v-flex xs12>
                <v-layout wrap style="background-color: #f2f2f2">
                  <v-flex
                    xs1
                    align-self-center
                    style="background-color: #f2f2f2"
                  >
                    <v-icon @click="firstCard=true,secondCard=false">mdi-chevron-left</v-icon>
                  </v-flex>
                  <v-flex
                    xs10
                    py-3
                    style="
                      background-color: #f2f2f2;
                      margin-bottom: 1px solid grey;
                    "
                  >
                    <span class="popregular ml">Add Your Information.</span>
                  </v-flex>
                  <v-flex xs1 py-3>
                    <v-spacer></v-spacer>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12 py-6 text-center style="color: grey">
                <span class="popregular medium">Who's giving today?</span>
                <br />

                <i>
                  <span class="popregular"
                    >We’ll never share this information with anyone</span
                  ></i
                >
              </v-flex>
              <!-- <v-flex xs6 px-5>
                <v-select
                  outlined
                  ref="country"
                  rounded
                  dense
                  color="#FF1313"
                  label="National Of"
                  placeholder="Country"
                  class="slotText"
                  :items="regions"
                  :rules="[rules.required]"
                  v-model="country"
                  item-text="name"
                  item-value="name"
                  item-color="#FF1313"
                >
                </v-select>
              </v-flex> -->
              <v-flex xs12 px-5>
                <v-text-field
                  type="number"
                  ref="amount"
                  color="#828282"
                  placeholder="Amount"
                  label="Amount"
                  :prepend-inner-icon="sign"
                  outlined
                  rounded
                  class="center2"
                  :rules="[rules.required]"
                  v-model="donation"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 px-5>
                <v-text-field
                  ref="firstname"
                  color="#828282"
                  prepend-inner-icon="mdi-account"
                  label="First Name"
                  rounded
                  outlined
                  class="center2"
                  :rules="[rules.required]"
                  v-model="firstname"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 px-5>
                <v-text-field
                  ref="lastname"
                  color="#828282"
                  prepend-inner-icon="mdi-account"
                  label="Last Name"
                  rounded
                  outlined
                  class="center2"
                  :rules="[rules.required]"
                  v-model="lastname"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 px-5>
                <v-text-field
                  ref="email"
                  color="#828282"
                  prepend-inner-icon="mdi-email"
                  outlined
                  rounded
                  class="center2"
                  label="Email"
                  :rules="[rules.email]"
                  v-model="email"
                ></v-text-field>
              </v-flex>
               <template v-if="taxExemption == true">
               <v-flex xs12 px-5>

               
                <v-text-field
                  color="#828282"
                  prepend-inner-icon="mdi-pencil"
                  outlined
                  rounded
                  class="center2"
                  label="Address"
                  :rules="[rules.required]"
                  v-model="Newaddress"
                ></v-text-field>
              </v-flex>
               <v-flex xs12 px-5>
                <v-text-field
                  color="#828282"
                  prepend-inner-icon="mdi-pen"
                  outlined
                  rounded
                  class="center2"
                  label="City"
                  :rules="[rules.required]"
                  v-model="Newcity"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 px-5>
                <v-text-field
                  color="#828282"
                  prepend-inner-icon="mdi-pen"
                  outlined
                  rounded
                  class="center2"
                  label="Pincode"
                  :rules="[rules.required]"
                  v-model="Newpincode"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 px-5>
                <v-text-field
                  color="#828282"
                  prepend-inner-icon="mdi-pen"
                  outlined
                  rounded
                  class="center2"
                  label="Pan Number"
                  :rules="[rules.required]"
                  v-model="Newpannumber"
                ></v-text-field>
              </v-flex>
              </template>
      

           <!-- <v-flex xs12 px-5 pt-2>
                <v-checkbox
                  v-model="isanonymous"
                  hide-details=""
                  class="LBL"
                  :value="true"
                  label="Make my donation anonymous "
                ></v-checkbox>
              </v-flex> -->
              <v-flex xs12 px-5 pt-2>
                <v-checkbox
                  v-model="taxExemption"
                  hide-details=""
                  class="LBL"
                  :value="true"
                  label="I wish to claim tax exemption against this donation"
                ></v-checkbox>
              </v-flex>

              <v-flex xs12 px-5 >
                <v-checkbox
                  v-model="isanonymous"
                  hide-details=""
                  class="LBL"
                  :value="true"
                  label="Make my donation anonymous "
                ></v-checkbox>
              </v-flex>
              <v-flex xs12 px-5>
                <v-checkbox
                  v-model="subscribe"
                  hide-details=""
                  class="LBL"
                  :value="true"
                  label="
                Subscribe to our newsletter for inspiring stories from our
                projects"
                ></v-checkbox>
              </v-flex>

              <v-flex xs12 sm6 py-8>
                <v-btn
                  @click="validate()"
                  height="60px"
                  color="#e27725"
                  dark
                  style="
                    font-size: 22px;
                    text-transform: none;
                    font-family: poppinsregular;
                  "
                  block
                  >Donate Now
                </v-btn>
              </v-flex>

              <v-flex
                class="popregular"
                xs12
                py-5
                text-center
                style="background-color: #f2f2f2"
              >
                <span><v-icon small>mdi-lock</v-icon> </span>
                <span>Secure Donation</span>
              </v-flex>
            </v-layout>
          </v-form>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
        Newaddress:'',
      Newcity:"",
      Newpincode:"",
      Newpannumber:"",
      taxExemption:false,

      BTN1: 5000,
      BTN2: 10000,
      BTN3: 20000,
      sign: "₹",
      Cname: "INR",
      //ssss
      outline1: true,
      outline2: false,
      outline3: false,
      outline4: false,
      dense: null,
      appLoading: false,
      showSnackBar: false,
      msg: "",
      timeout: 3000,
      ServerError: false,

      rules: {
        required: (value) => !!value || "Required.",
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },
      amountRules: [
        (v) => !!v || "required",

        // (v) => (v && v.length < 7) || "Amount is too large",
      ],
      donation: 5000,
      firstCard: true,
      secondCard: false,
      formHasErrors: false,
      errors: [],
      country: "India",
      amount: null,
      firstname: null,
      lastname: null,
      mobile: null,
      email: null,
      address: null,
      city: null,
      state: null,
      postalcode: null,
      panno: null,
      hearaboutus: "",
      comments: null,
      isanonymous: false,
      subscribe: false,
      symbol: "₹",
      regionssymbol: [],
      message: "",
      resource: null,
      regions: [],
    };
  },
  watch: {
    country() {
      if (this.country != "India") {
        this.BTN1 = 100;
        this.BTN2 = 200;
        this.BTN3 = 500;
        this.sign = "$";
        this.Cname = "USD";
        this.donation = this.BTN1;
        //  console.log("true not india")
      } else {
        this.BTN1 = 5000;
        this.BTN2 = 10000;
        this.BTN3 = 20000;
        this.sign = "₹";
        this.Cname = "INR";
        this.donation = this.BTN1;

        //  console.log("false india")
      }
    },
  },
  mounted() {
    if(this.$route.query.secOn == 2) {
      this.firstCard=false
      this.secondCard=true
    }
    if(this.$route.query.amt) {
      
      alert("hi")
      this.donation=this.$route.query.amt
      
    }
  },
  beforeMount() {

    
    axios({
      method: "GET",
      url: "/country/all",
    })
      .then((response) => {
        this.regions = response.data.data;
      })
      .catch((err) => {
        console.log(err);
      });
    // this.getData();
  },
  methods: {
    assign(val) {
      this.donation = val;
    },
    validate() {
      if (this.$refs.file.validate()) {
        var don = {};
        this.appLoading = true;
        don["country"] = this.country;
        don["amount"] = this.donation;
        don["firstname"] = this.firstname;
        don["lastname"] = this.lastname;
        don["donationtype"] = this.$route.query.DonationName;
        don["email"] = this.email;
        don["isanonymous"] = this.isanonymous;
        don["subscribe"] = this.subscribe;
        don["address"] = this.Newaddress;
        don["city"] = this.Newcity;
        don["postalcode"] = this.Newpincode;
        don["panno"] = this.Newpannumber;
        don["taxExemption"] = this.taxExemption;
        axios({
          method: "post",
          url: "/payment/initiate",
          data: don,
        })
          .then((response) => {
            if (response.data.status) {
              localStorage.setItem("currencySymbol", this.symbol);
              this.appLoading = false;
              this.$router.push(
                "/payment/?id=" +
                  response.data.id +
                  "&country=" +
                  this.country +
                  "&event=" +
                  this.$route.query.DonationName
              );
            } else {
              this.appLoading = false;
              this.msg = response.data.msg;
              this.showSnackBar = true;
            }
          })
          .catch(() => {
            this.appLoading = false;
            this.ServerError = true;
          });
      } else {
        this.showSnackBar = true;
        this.msg = "please complete your form";
      }
    },
    toTextField() {
      this.donation = null;
      document.getElementById("my_button").onclick = function () {
        document.getElementById("my_textbox").focus();
      };
    },
  },
};
</script>
<style  scoped>
.LBL >>> label{
  font-family: poppinsregular !important;
  font-size:12px !important
  
}
.v-text-field--outlined >>> fieldset {
  border-color: grey;
  border-radius: 5px;
}
.v-radio >>> label {
  font-size: 20px;
  font-family: poppinsregular;
  color: black;
}
.center >>> input {
  text-align: center;
  font-family: poppinsregular;
  font-size: 32px;
}
.center2 >>> label {
  text-align: center;
  font-family: poppinsregular;
  font-size: 20px;
}
.center4 {
 text-align: center !important;
  font-family: poppinsregular !important;
 
}
.boldR >>> label {
  font-family: poppinsbold;
}
.animate {
  position: relative;
  animation: mymove 0.25s;
}
@keyframes mymove {
  from {
    left: -500px;
  }
  to {
    left: 0px;
  }
}
#div1 {
  animation-timing-function: linear;
}

.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear; /* Safari */
  animation: spin 2s linear;
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
